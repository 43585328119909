import * as React from 'react';
import {MobileMenu} from './MobileMenu';
import Kitty from '../assets/svg/kitty.svg';
import {Link} from 'gatsby';

export const Header = ({language}) => {
  return (
    <>
      <HeaderLayout language={language} />
      <MobileMenu language={language} />
    </>
  );
};

const HeaderLayout = ({language}) => (
  <header className="header">
    <nav className="content header__content">
      <Link to={'/'} className={'logo header__logo'}>
        <Kitty className={'logo__kitty'} />
        <div className="logo__text">{content[language].logoText}</div>
      </Link>

      <Kitty className="header__mobile-logo" />

      <Link to={'#who-we-are'} className="header__menu-item">
        {content[language].whoWeAre}
      </Link>
      <Link to={'#how-to-help'} className="header__menu-item">
        {content[language].howToHelp}
      </Link>
      <Link to={'#how-to-help'} className="button button_yellow header__button">
        {content[language].donate}
      </Link>
      {language === 'ua' ? (
        <Link to={'/en'} className="header__language-button">
          EN
        </Link>
      ) : (
        <Link to={'/'} className="header__language-button">
          UA
        </Link>
      )}
    </nav>
  </header>
);

const content = {
  en: {
    whoWeAre: 'About us',
    howToHelp: 'How to help',
    donate: 'DONATE',
    logoText: 'soloma \ncats',
  },
  ua: {
    whoWeAre: 'ПРО НАС',
    howToHelp: 'ЯК НАМ ДОПОМОГТИ',
    donate: 'ПІДТРИМАТИ',
    logoText: 'соломʼянські \nкотики',
  },
};
